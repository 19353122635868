<template>
  <div class="aside">
    <el-menu
      :default-active="router_name"
      class="el-menu-vertical-demo"
      background-color="#333"
      text-color="#fff"
      active-text-color="#fff"
      >
      <h5 class="title">服务机构管理</h5>
      <el-menu-item v-for="item in tab" :index="item.name" :key="item.name" @click="handleMenu(item)">
          <i :class="item.icon"></i>
          <span slot="title">{{item.title}}</span>
      </el-menu-item>
    </el-menu>
    <div >&nbsp;</div>
  </div>
</template>

<script>
export default {
  name: 'CommonAside',
  data () {
    return {
      tab: [
        { title: '统计分析', name: 'information', icon: 'el-icon-s-home', path: '/information' },
        { title: '客户管理', name: 'customer', icon: 'el-icon-s-custom', path: '/customer' },
        { title: '机构资料', name: 'institutions', icon: 'el-icon-document', path: '/institutions' }
      ]
    };
  },
  created () {

  },
  computed: {
    router_name () {
      // return this.$store.state.backstage.router_name
      return sessionStorage.getItem('menuRoute');
    }
  },
  methods: {
    handleMenu (item) {
      this.$router.push({
        name: item.name
      });
      // this.$store.commit('backstage/set_router_name',item.name)
      sessionStorage.setItem('menuRoute', item.name);
    }
  }
};
</script>

<style lang="less" scoped>
.aside{
    .title{
        height: 60px;
        background: #156ED0;
        color: #fff;
        font-size: 20px;
        margin-top: 0;
        line-height: 60px;
        margin-bottom: 20px;
        font-weight: bold;
    }
}
.el-menu-item.is-active {
  background-color: #156ED0 !important;
}
.el-menu-item{
  text-align: left;
}
</style>
