<template>
  <div class="header">
    <div class="middle">&nbsp;</div>
    <div class="user">
      <!-- <span style="font-size: 14px;margin-right:30px">申报通</span> -->
      <span style="font-size: 14px" @click="goReturn">退出</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonHeader',
  data () {
    return {
      professional: '产品',
      username: 'wfl'
    };
  },
  methods: {
    goReturn () {
      this.$confirm('此操作将返回前台, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '跳转成功!'
          });
          this.$router.push({ path: '/' });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消跳转'
          });
        });
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  background: #156ED0;
  height: 60px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  .middle {
    flex: 1;
  }
  .user {
    margin-right: 20px;
    font-size: 16px;
    // border-left: 1px solid #fff;
    padding: 10px 20px;
  }
}
.el-dropdown {
  color: #fff;
}
</style>
