<template>
  <el-container style="height: 100vh">
    <el-aside width="10%"><common-aside></common-aside></el-aside>
    <el-container>
      <el-header><common-header></common-header> </el-header>
      <el-main><router-view></router-view></el-main>
    </el-container>
  </el-container>
</template>

<script>
import CommonHeader from './components/CommonHeader.vue';
import CommonAside from './components/CommonAside.vue';
export default {
  components: {
    CommonHeader,
    CommonAside
  },
  name: 'backstage',
  data () {
    return {};
  }
};
</script>

<style lang="less" scoped>
.el-header{
    color: #333;
    text-align: center;
    padding: 0;
  }

  .el-aside {
    background-color: #333;
    text-align: center;
  }

  .el-main {
    background-color: #eee;
    color: #000;
    padding-top: 0;
  }
</style>
